import updateCurrentUserMutation from 'graphql/mutations/updateCurrentUser.graphql';
import Form from 'components/atoms/Form';

const NameForm = ({ currentUser }) => (
  <Form.Factory
    mutation={updateCurrentUserMutation}
    action="updateCurrentUser"
    fields={[
      {
        name: 'lastName',
        label: 'Фамилия',
        initialValue: currentUser.lastName || '',
        type: 'text',
      },
      {
        name: 'firstName',
        label: 'Имя',
        initialValue: currentUser.firstName || '',
        type: 'text',
        required: true,
      },
      {
        name: 'middleName',
        label: 'Отчество',
        initialValue: currentUser.middleName || '',
        type: 'text',
      },
    ]}
  />
);

export default NameForm;
