import updateCurrentUserMutation from 'graphql/mutations/updateCurrentUser.graphql';
import Form from 'components/atoms/Form';

const PasswordForm = () => (
  <Form.Factory
    mutation={updateCurrentUserMutation}
    action="updateCurrentUser"
    fields={[
      {
        name: 'currentPassword',
        label: 'Текущий пароль',
        initialValue: '',
        type: 'password',
        resetAfterSuccess: true,
      },
      {
        name: 'password',
        label: 'Новый пароль',
        initialValue: '',
        type: 'password',
        resetAfterSuccess: true,
      },
      {
        name: 'passwordRepeat',
        label: 'Повтор пароля',
        initialValue: '',
        type: 'password',
        resetAfterSuccess: true,
      },
    ]}
    successMessage="Пароль успешно изменён"
    submitText="Сменить пароль"
  />
);

export default PasswordForm;
