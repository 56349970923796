import styled from 'styled-components';
import ProfileTemplate from 'components/templates/ProfileTemplate';
import Delimiter from 'components/atoms/Delimiter';
import NameForm from './NameForm';
import PhoneForm from './PhoneForm';
import AddressForm from './AddressForm';
import PasswordForm from './PasswordForm';
import EmailForm from './EmailForm';

const Wrapper = styled.div`
  max-width: 40em;
`;

const ProfileSettingsPage = ({ currentUser }) => (
  <ProfileTemplate>
    <Wrapper>
      <NameForm currentUser={currentUser} />
      <Delimiter />
      <PhoneForm currentUser={currentUser} />
      <Delimiter />
      <AddressForm currentUser={currentUser} />
      <Delimiter />
      <EmailForm currentUser={currentUser} />
      <Delimiter />
      <PasswordForm />
    </Wrapper>
  </ProfileTemplate>
);

export default ProfileSettingsPage;
