import updateCurrentUserMutation from 'graphql/mutations/updateCurrentUser.graphql';
import Form from 'components/atoms/Form';

const EmailForm = ({ currentUser }) => (
  <Form.Factory
    mutation={updateCurrentUserMutation}
    action="updateCurrentUser"
    fields={[
      {
        name: 'currentEmail',
        label: 'Текущий E-mail',
        initialValue: currentUser.email,
        type: 'email',
        readOnly: true,
      },
      {
        name: 'email',
        label: 'Новый E-mail',
        initialValue: '',
        type: 'email',
        resetAfterSuccess: true,
      },
    ]}
    successMessage={
      <>
        E-mail будет изменён после выполнения инструкций в письме, отправленном на{' '}
        <strong>новый</strong> E-mail.
      </>
    }
    submitText="Сменить E-mail"
  />
);

export default EmailForm;
