import updateCurrentUserMutation from 'graphql/mutations/updateCurrentUser.graphql';
import Form from 'components/atoms/Form';

const AddressForm = ({ currentUser }) => (
  <Form.Factory
    mutation={updateCurrentUserMutation}
    action="updateCurrentUser"
    fields={[
      {
        name: 'address',
        label: 'Адрес',
        initialValue: currentUser.address || '',
        type: 'text',
      },
    ]}
  />
);

export default AddressForm;
